import React, { useLayoutEffect, useState } from 'react';
import debounce from "lodash.debounce"

function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  const CachedWidth = React.useRef(null);
  useLayoutEffect(() => {
    function updateSize() {
      debouncedUli([window.innerWidth, window.innerHeight])
    }

    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  const uli = debounce(
    (value) => {
      setSize(value);

      // only prevent vh update if width hasn't changed on mobile
      if(value[0] === CachedWidth.current && value[0] < 900) 
        return;

      let vh = value[1] * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
      CachedWidth.current = value[0];
      
      
      
    },
    300
  )

  const debouncedUli = React.useCallback((value) => uli(value), [])

  return size;
}

export default useWindowSize;