/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it

import * as React from "react"
import { SiteProvider } from "./src/context/site-context"
import { Apollo } from './src/context/apollo-context'
import PageTrack from './src/context/page-track';

export const wrapRootElement = ({ element, props }) => (
  <SiteProvider>
    <Apollo>
      {element}
    </Apollo>
  </SiteProvider>
)


export const wrapPageElement = ({ element, props }) => {
  return <><PageTrack/>{element}</>
}

export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  const { pathname, state } = location;
  const scrollToTopRoutes = [`/`];

  if (scrollToTopRoutes.indexOf(pathname) !== -1) {
    //window.history.scrollRestoration = "manual";
    // transition duration from `layout.js` * 1000 to get time in ms
    // * 2 for exit + enter animation
    const TRANSITION_DELAY = 150;
    const savedPosition = state.logo ? [0,2000] : getSavedScrollPosition(location, location.key);
    console.log(savedPosition);

    if(savedPosition) {
      window.setTimeout(
        () => window.scrollTo(...savedPosition),
        TRANSITION_DELAY
      );
  
      return false;

    }
    

  }

  
};