import * as React from "react"
import { globalHistory } from '@reach/router'
import useWindowSize from "utils/useWindowSize";

const defaultValues = {}

export const SiteContext = React.createContext(defaultValues)

export const SiteProvider = ({ children }) => {
  const currentRoute = React.useRef();
  const cachedRoute = React.useRef();
  const [ width ] = useWindowSize();
  const [ isFilter, setFilter] = React.useState();
  const [ isMenu, setMenu] = React.useState();
  const [ isLoading, setIsLoading] = React.useState(false);
  const [ FilterRight, setFilterRight ] = React.useState();
  const [ jelliesRef, setJelliesRef ] = React.useState();
  const [ footerRef, setFooterRef ] = React.useState();
  const [ footerAttrRef, setFooterAttrRef ] = React.useState();
  const [ brandRef, setBrandRef ] = React.useState();
  const [ mobileCopyRef, setMobileCopyRef ] = React.useState();
  const [ navRef, setNavRef ] = React.useState();
  const [ homeIntroComplete, setHomeIntroComplete ] = React.useState(false);

  React.useEffect(() => {
    globalHistory.listen(({ action, location }) => {
      if (action === 'PUSH') {
        cachedRoute.current = currentRoute.current;
        currentRoute.current = location.pathname;
      }
    })
  },[])

  const setInitRoute = (route) => {
    currentRoute.current = route;
  }

  const jelliesReference = React.useCallback((node)=> {
    if( node !== null ) {
      setJelliesRef(node)
    }
  }, []);

  const brandReference = React.useCallback((node)=> {
    if( node !== null ) {
      setBrandRef(node)
    }
  }, []);

  const navReference = React.useCallback((node) => {
    if(node !== null){
      setNavRef(node);
    }
  }, []);

  const footerReference = React.useCallback((node) => {
    if(node !== null){
      setFooterRef(node);
    }
  }, []);

  const footerAttrReference = React.useCallback((node) => {
    if(node !== null){
      setFooterAttrRef(node);
    }
  }, []);

  const mobileCopyReference = React.useCallback((node) => {
    if(node !== null){
      setMobileCopyRef(node);
    }
  }, []);

  return (
    <SiteContext.Provider
      value={{
        ...defaultValues,
        width,
        setInitRoute,
        cachedRoute,
        currentRoute,
        isFilter,
        setFilter,
        FilterRight,
        setFilterRight,
        isMenu,
        setMenu,
        isLoading,
        setIsLoading,
        jelliesRef,
        jelliesReference,
        brandRef,
        brandReference,
        navRef,
        navReference,
        footerRef,
        footerReference,
        footerAttrRef,
        footerAttrReference,
        mobileCopyRef,
        mobileCopyReference,
        setHomeIntroComplete,
        homeIntroComplete
      }}
    >
      {children}
    </SiteContext.Provider>
  )
}