import React, { useEffect} from 'react';
import { useLocation } from '@reach/router'
import { SiteContext } from "context/site-context";


const PageTrack = () => {
  const { setInitRoute } = React.useContext(SiteContext);
  const initLocation = useLocation();

  useEffect(() => {
  // get first route
  if(setInitRoute && initLocation) {
    setInitRoute(initLocation.pathname);
  }
    
  }, [setInitRoute, initLocation]);

  return (
    <>
    
    </>
  )
}

export default PageTrack;