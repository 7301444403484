import * as React from "react"
import { ApolloClient, ApolloProvider, InMemoryCache } from "@apollo/client";
import { createPrismicLink } from "apollo-link-prismic";
import fetch from "node-fetch";

 const client = new ApolloClient({
	link: createPrismicLink({
		repositoryName: process.env.GATSBY_PRISMIC_REPO_NAME,
		// Provide your access token if your repository is secured.
		accessToken: process.env.GATSBY_PRISMIC_ACCESS_TOKEN,
    fetch
	}),
	cache: new InMemoryCache(),
});

export const Apollo = ({children}) => {

  return (
    <ApolloProvider client={client}>
      {children}
    </ApolloProvider>
  )
}